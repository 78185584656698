/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* Colors */
$c-hbb-green: #a2c641;
$c-hbb-darker-green: #91af3e;
$c-hbb-black: #2f2d2e;
$c-hbb-white: #ffffff;
$c-hbb-orange: #ff6e26;
$c-hbb-yellow: #feee00;
$c-hbb-grey: #c4c4c4;
$c-hbb-light-gray: #f6f6f6;
$c-hbb-red: #d30019;

$white: #{$c-hbb-white};
$black: #{$c-hbb-black};
$default-primary-base-color: #{$c-hbb-green};
$default-primary-dark-color: #{$c-hbb-darker-green};
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$color-dodger-blue: #3b9cfe;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;

/* Font Pluto */
$ff-pluto-heavy: 'PlutoHeavy';
$ff-pluto-regular: 'PlutoRegular';

/* Font sizes */
$fs-caption: 1.2rem;
$fs-body: 1.6rem;
$fs-heading-h1: 4rem;
$fs-heading-h2: 2.8rem;
$fs-heading-h3: 2rem;

/* Font weight */
$fw-normal: 400;
$fw-bold: 800;

/* Line height */
$lh-sm: 1.12;
